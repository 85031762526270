<template>
	<div class='a-card-order-price'>
		<div class='a-card-order-price-total'>
			<template v-if='loading_price'>
				<div class='a-card-order-price-loading'>
					<v-icon icon='loading' size='14' />
				</div>
			</template>
			<template v-else>
				<div class='a-card-order-price-not-available' v-if='!item.isAvailable'>
					<span class="a-card-order-price-not-available-link app-link green--text" @click='openEdit(item)'>{{$t('account.orders.replace')}}</span>
					<p class='a-card-order-price-not-available-text orange--text'>({{$t('account.orders.not_available_for_your_request')}})</p>
				</div>
				<span class='a-card-order-text-1' v-else><strong>{{$helpers.amount(price.basePrice.amount, price.basePrice.currency)}}</strong> ({{$t("account.itinerary.markup")}}: {{$helpers.amount(price.userMargin.amount, price.userMargin.currency)}})</span>
			</template>
		</div>
		<div class='a-card-order-price-links'>
<!--			<span class='a-card-order-link' @click='dialog_markup = true'>{{$t("account.buttons.edit_price")}}</span>-->
<!--			<span class='a-card-order-link' @click='dialog_delete = true'>{{$t('account.buttons.remove')}}</span>-->
<!--			<span class='a-card-order-link' @click='openEdit(item)'>{{$t("account.buttons.edit")}}</span>-->
		</div>

		<a-dialog-markup
			:orderId='order.id'
			:item='item'
			:title='titleMarkup'
			:titleIcon='titleIconMarkup'
			v-model='dialog_markup'
			:price='price'
			@update-order='$emit("updateSuitcase")'
			@close-dialog="dialog_markup = false" />

		<a-dialog-delete
			v-model='dialog_delete'
			:title="$t('account.orders.are_you_sure_you_want_to_delete_this_service')"
			@close-dialog="dialog_delete = false"
			@action-dialog='deletedItemOrder'
			:loading='loading_btn'
		/>

		<orders-edit
			:title='title'
			v-model='edit'
			@input='edit = $event'
		>
			<template>
				<component
					:is="component"
					:edit='true'
					:searchData='searchData'
					:initialTripName='tripName'
					@update='edit = false'
				/>
			</template>
		</orders-edit>

		<list-insurance
			v-model='insurance'
			@open-edit='openInsurance($event)'
			:title='$t("account.itinerary.insurance.add_insurance")'
			:descr='$t("account.itinerary.insurance.choose_tourist_insurance")'
			:notCloseDialog='edit_insurance'
			:order='order'
			:items='findTrip("insurance")'
		/>
		<a-edit-insurance
			v-model='edit_insurance'
			:user='item_tourist'
			:order='order'
			@update-order="$emit('update-order', $event)"
			:initialItems='findTrip("insurance")'
		/>

		<list-visa
			v-model='visa'
			@open-edit='openVisa($event)'
			:title='$t("account.itinerary.visa.add_visa")'
			:descr='$t("account.itinerary.visa.choose_tourist_visa")'
			:notCloseDialog='edit_visa'
			:order='order'
			:items='findTrip("visa")'
		/>
		<a-edit-visa
			v-model='edit_visa'
			:user='item_tourist'
			:order='order'
			@update-order="$emit('update-order', $event)"
			:initialItems='findTrip("visa")'
		/>
	</div>
</template>

<script>
	import ADialogMarkup from '@/views/account/itinerary/dialogs/markup';
	import ADialogDelete from '@/components/account/dialogs/delete';
	import OrdersEdit from '@/views/account/search/components/edit';
	import AHomeFlights from "@/views/account/home/flights";
	import AHomeHotels from "@/views/account/home/hotels";
	import AHomeTransfers from "@/views/account/home/transfers";
	import AHomeCharterFlights from "@/views/account/home/charter-flight";
	import ListInsurance from "@/views/account/itinerary/components/list-insurance";
	import ListVisa from "@/views/account/itinerary/components/list-visa";
	import AEditInsurance from "@/views/account/itinerary/insurance-visa/edit-insurance";
	import AEditVisa from "@/views/account/itinerary/insurance-visa/edit-vise";
	import { deletedItemOrder } from '@/services/request';

	export default {
        components: {
            ADialogMarkup,
            ADialogDelete,
						OrdersEdit,
            AHomeFlights,
            AHomeHotels,
            AHomeTransfers,
            AHomeCharterFlights,
            ListInsurance,
            ListVisa,
            AEditInsurance,
            AEditVisa
        },
		data: () => ({
			dialog_markup: false,
			dialog_delete: false,
			edit: false,
			title: null,
			component: null,
			searchData: null,
			tripName: null,
			loading_btn: false,

			insurance: false,
			edit_insurance: false,
			visa: false,
			edit_visa: false,
			item_tourist: {}
		}),
		props: {
			order: Object,
			item: Object,
			price: Object,
			loading_price: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			titleMarkup() {
				switch (this.item.type) {
					case 'flight':
					case 'charterFlight': {
						return `${this.$t("account.itinerary.timeline.flight_to")} ${this.item.itineraries[0].DestinationLocName}`
					}
					case 'hotel': {
						const night = this.$moment(this.item.searchData.dateTo).diff(this.$moment(this.item.searchData.dateFrom), "days");
						return `${night} ${this.$helpers.declOfNum(night, this.$t('account.declOfNum.nights'))}, ${this.item.name}`;
					}
					case 'transfer': {
						return `${this.$t("account.itinerary.timeline.transfer_to")} ${this.item.contract[0].destinationLocationName}`
					}
					default:
						return this.$t(`account.orders.${this.item.type}`);
				}
			},
			titleIconMarkup() {
				switch (this.item.type) {
					case 'flight':
					case 'charterFlight': {
						return 'navigation/flights'
					}
					case 'hotel': {
						return 'navigation/hotels'
					}
					case 'transfer': {
						return 'navigation/transfers'
					}
					case 'insurance': {
						return 'navigation/insurance'
					}
					case 'visa': {
						return 'navigation/visa'
					}
					default:
						return 'info'
				}
			}
		},
		methods: {
			openEdit({id, type, searchData}) {
				if(type === 'insurance' || type === 'visa') {
					switch (type) {
						case 'insurance': {
							this.insurance = true;
							return true;
						}
						case 'visa': {
							this.visa = true;
							return true;
						}
						default:
							return false
					}
				} else {
					this.edit = true;
					this.searchData = {...searchData, ...(id ? {replaceOfferId: id} : {})};
					this.tripName = this.order.name ?? this.$t("account.orders.not_specified");

					switch (type) {
						case 'flight': {
							this.title = this.$t("account.search.title.flights")
							this.component = 'AHomeFlights';
							return true;
						}
						case 'hotel': {
							this.title = this.$t("account.search.title.hotels")
							this.component = 'AHomeHotels';
							return true;
						}
						case 'transfer': {
							this.title = this.$t("account.search.title.transfers")
							this.component = 'AHomeTransfers';
							return true;
						}
						case 'charterFlight': {
							this.title = this.$t("account.search.title.charter-flight")
							this.component = 'AHomeCharterFlights';
							return true;
						}
						default:
							return false
					}
				}
			},
			deletedItemOrder() {
				this.loading_btn = true;

				deletedItemOrder(this.order.id, this.item.id)
					.then(() => {
						this.$emit('updateSuitcase');
						this.dialog_delete = false;
					})
					.finally(() => this.loading_btn = false)
			},
			openInsurance($event) {
				this.item_tourist = $event;
				this.edit_insurance = true;
			},
			openVisa($event) {
				this.item_tourist = $event;
				this.edit_visa = true;
			},
			findTrip(name) {
				if(!this.order.items) return;

				if(this.order.items.find(el => el.type == name)) {
					switch(name) {
						case 'insurance':
							return this.order.items.find(el => el.type == name).insurances
						case 'visa':
							return this.order.items.find(el => el.type == name).visas
						default:
							return []
					}
				} else {
					return [];
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-card-order-price {
		&-total {
			margin-bottom: 5px;
		}
		&-links {
			.a-card-order-link {
				&:not(:last-child) {
					margin-right: 20px;
				}
			}
		}
		&-loading {
			height: 20px;
			display: grid;
			align-items: center;
			color: $green-persian;
			.app-icon {
				animation: rotating .6s linear infinite;
				svg {
					fill: none;
					path {
						stroke: currentColor;
						stroke-width: 2px;
					}
					circle {
						stroke: currentColor;
						stroke-width: 2px;
						stroke-opacity: .25;
					}
				}
			}
		}
		&-not-available {
			&-link {
				display: inline-block;
				font-size: 14px;
				margin-right: 15px;
			}
			&-text {
				display: inline-block;
				font-size: 10px;
				font-weight: 400;
			}
		}
	}

	@keyframes rotating {
		from { transform: rotate(0deg); }
		to { transform: rotate(360deg); }
	}
</style>
