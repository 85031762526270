<template>
	<div class="a-card-order-item">
		<div class="a-card-order-item-row">
			<div class="a-card-order-item-col direction">
				<a-card-direction :item='item'>
					<template>
						<a-card-info-text
							:title="titleDate"
							:text='date'
							class='a-card-order-info-date desktop-show'
							v-if='date.length'
						/>
					</template>
				</a-card-direction>
			</div>
			<div class="a-card-order-item-col date desktop-hide">
				<a-card-info-text
					:title="titleDate"
					:text='date'
					v-if='date.length'
				/>
			</div>
			<div class="a-card-order-item-col price">
				<a-card-price
					:order='order'
					:item='item'
					:price='item.price'
					:loading_price='loading_price'
					@updateSuitcase='$emit("updateSuitcase")'
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import ACardDirection from '@/views/account/orders/card-direction';
	import ACardInfoText from '@/views/account/orders/card-info-text';
	import ACardPrice from '@/views/account/orders/card-price';

	export default {
		props: ['item', 'loading_price', 'order'],
		components: {
			ACardDirection,
			ACardInfoText,
			ACardPrice
		},
		computed: {
			titleDate() {
				return `${this.$t('account.date')} ${this.$t('account.with')} ${this.$helpers.diffDays(this.item.dateFrom, this.item.dateTo) > 0 ? `- ${this.$t('account.by')}` : ""}`;
			},
			date() {
				if(this.item.dateFrom) {
					return `${this.$moment(this.item.dateFrom).format('DD/MM/YYYY')} ${this.$helpers.diffDays(this.item.dateFrom, this.item.dateTo) > 0 ? `- ${this.$moment(this.item.dateTo).format('DD/MM/YYYY')}` : ""}`;
				} else {
					return "";
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-card-order-item {
		padding: 20px 15px;
		@include minw( $grid-breakpoints-xs ) {
			padding: 20px 30px;
		}
		&-row {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -15px;
			@include maxw( $grid-breakpoints-xs - 1 ) {
				flex-direction: column;
			}
		}
		&-col{
			padding: 0 15px;
			&.direction {
				flex-grow: 1;
				@include minw( $grid-breakpoints-xs ) {
					width: calc(100% - 260px - 280px);
				}
			}
			&.date {
				flex-shrink: 0;
				width: 260px;
			}
			&.price {
				flex-shrink: 0;
				@include minw( $grid-breakpoints-xs ) {
					width: 280px;
				}
				@include maxw( $grid-breakpoints-xs - 1 ) {
					margin-top: 15px;
					margin-left: 30px;
				}
			}
		}
	}
</style>
