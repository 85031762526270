<template>
	<div class="a-card-order-header">
		<div class="a-card-order-header-row">
			<div class="a-card-order-header-col title">
				<div class='a-card-order-info'>
<!--					<div class='a-card-order-info-flex'>-->
<!--						<span class='a-card-order-title-1'>{{order.name ? order.name : $t("account.orders.not_specified")}}</span>-->
<!--						<span class='a-card-order-link' @click='$emit("open-dialog-change", {orderId: order.id, value: order.name, key: "name"})'>{{$t("account.buttons.change")}}</span>-->
<!--					</div>-->
					<p class='a-card-order-title-2'>ID: {{order.id}}</p>
					<v-button class="a-card-order-status" block xxsmall color="red white--text" v-if="order.status.code === 'cancellation'">Canceled</v-button>
					<v-button class="a-card-order-status" block xxsmall color="green-color white--text" v-if="order.status.code === 'paid'">Booked</v-button>
				</div>
			</div>
			<div class="a-card-order-header-col">
<!--				<a-card-info-text-->
<!--					:title="$t('account.orders.tour_created_for')"-->
<!--					:text='order.client'-->
<!--					:link='() => openDialogs({orderId: order.id, value: order.client, key: "client"})'-->
<!--				/>-->
			</div>
			<div class="a-card-order-header-col">
<!--				<a-card-info-text-->
<!--					:title="$t('account.orders.phone')"-->
<!--					:text="order.phone | VMask('+### (##) ### ## ##')"-->
<!--					:link='() => openDialogs({orderId: order.id, value: order.phone, key: "phone", mask: "+380 ## ### ## ##"})'-->
<!--				/>-->
			</div>
			<div class="a-card-order-header-col">
<!--				<a-card-info-text-->
<!--					title='E-mail'-->
<!--					:text='order.email'-->
<!--					:link='() => openDialogs({orderId: order.id, value: order.email, key: "email"})'-->
<!--				/>-->
			</div>
			<div class="a-card-order-header-col date">
				<a-card-info-text
					:title="$t('account.orders.data_created')"
					:text='$moment(order.updatedAt).format("DD/MM/YYYY")'
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import ACardInfoText from '@/views/account/orders/card-info-text';
	import masker from 'vue-the-mask/src/masker';
	import tokens from 'vue-the-mask/src/tokens';

	export default {
		props: ['order'],
		filters: {
			VMask: (value, mask) => masker(value, mask, true, tokens)
		},
		components: {
			ACardInfoText
		},
		methods: {
			openDialogs($event) {
				this.$emit("open-dialog-change", $event)
			}
		}
	}
</script>

<style lang="scss">
	@import "@/styles/global/variables";
	@import "@/styles/global/mixins";

	.a-card-order-header {
		padding: 15px;
		border-bottom: 1px solid rgba($black, .1);
		@include minw( $grid-breakpoints-xs ) {
			padding: 20px 30px;
		}
		&-row {
			display: flex;
			margin: 0 -15px;
			@include maxw( $grid-breakpoints-sm - 1 ) {
				flex-wrap: wrap;
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				flex-direction: column;
			}
		}
		&-col {
			flex-grow: 1;
			padding: 0 15px;
			@include maxw( $grid-breakpoints-sm - 1 ) {
				width: 50%;
				margin-bottom: 15px;
				&.title {
					width: 100%;
					margin-bottom: 20px;
				}
			}
			@include maxw( $grid-breakpoints-xs - 1 ) {
				width: 100%;
			}
			.a-card-order-title-2 {
				margin-top: 2px;
			}
			.a-card-order-status{
				font-size: 13px;
				height: 30px;
				width: 100px!important;
				min-width: 100px!important;
			}
			&.title {
				.a-card-order-title-1 {
					line-height: 1.25;
				}
				@include minw( $grid-breakpoints-sm ) {
					flex-grow: 0;
					width: 276px;
					.a-card-order-info-flex {
						display: block;
						flex-wrap: nowrap;
					}
				}
			}
			&.date {
				flex-grow: 0;
			}
		}
	}
</style>
